import { Controller } from '@hotwired/stimulus'
import _ from 'underscore'
import { Idiomorph } from 'vendor/idiomorph'

export default class extends Controller {
  get embedded() {
    return this._embedded || (
      this._embedded = this.findController('embedded')
    )
  }

  morph (element, innerHTML) {
    Idiomorph.morph(
      element,
      innerHTML,
      { morphStyle:'innerHTML' }
    )
  }

  render (template, locals = {}) {
    let names  = Object.keys(locals)
    let values = Object.values(locals)
    let parser = new Function(...names, `return \`${template}\`;`).bind(this)
    return parser(...values)
  }

  findParentController (identifier) {
    return this.controllerFor(this.element.closest(`[data-controller*="${identifier}"]:not([data-controller*="${identifier}--"])`), identifier)
  }

  findChildController (identifier) {
    let element = this.element.querySelector(`[data-controller*="${identifier}"]:not([data-controller*="${identifier}--"])`)
    if (element) {
      return this.controllerFor(element, identifier)
    }
  }

  findChildControllers (identifier) {
    return _.compact(
      [
        ...this.element.querySelectorAll(`[data-controller*="${identifier}"]:not([data-controller*="${identifier}--"])`)
      ].map(
        (element) => this.controllerFor(element, identifier)
      )
    )
  }

  findControllers (identifier) {
    return _.compact(
      [
        ...document.querySelectorAll(`[data-controller*="${identifier}"]:not([data-controller*="${identifier}--"])`)
      ].map(
        (element) => this.controllerFor(element, identifier)
      )
    )
  }

  findController (identifier) {
    return this.findControllers(identifier)[0]
  }

  controllerFor(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier)
  }
}
