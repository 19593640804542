import _ from 'underscore'
import SelectScrollController from 'controllers/select_scroll_controller'
import RealtimeWidgetController from 'controllers/realtime_widget_controller'

export default class extends SelectScrollController {

  static targets = ['input']

  static values  = {
    itemsPerGroup: { type: Number, default: 4  },
    openingHours:  { type: Object, default: {} },
    holidayHours:  { type: Array,  default: [] }
  }

  static outlets = ['realtime-widget']

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  get timeslots() {
    return Array.from(
      this.inputTarget.querySelectorAll('option')
    ).filter(
      (option) => option.value.length > 0
    )
  }

  get value() {
    return this.inputTarget.value
  }

  set timeslots(newTimeslots) {
    let currentValue = this.value

    this.morph(this.inputTarget, newTimeslots.map(
      (timeslot) => {
        let selected = (timeslot === currentValue) ? ' selected' : ''
        return `<option value="${timeslot}"${selected}>${timeslot}</option>`
      }
    ).join())

    this.refreshItems()
  }

  connect() {
    _.defer(() => this.initializeSelectScroll())
  }

  refreshTimeslots() {
    let booking     = this.widget.booking
    let currentDate = booking.date

    if (currentDate) {
      let availability = this.availability = this.widget.booking.availabilityForDate(currentDate)

      let weekDay = currentDate.getDay()
      weekDay = (weekDay === 0) ? 6 : weekDay - 1

      this.timeslots = this.presentValuesOf(
        availability && availability.timeslots,
        availability && availability.hasOpeningHoursValue && availability.openingHoursValue[weekDay],

        booking.hasOpeningHoursValue && booking.openingHoursValue[weekDay],
        booking.hasOpeningHoursValue && this.mergedValuesOf(...Object.values(booking.openingHoursValue)),

        this.hasOpeningHoursValue && this.openingHoursValue[weekDay],
        this.hasHolidayHoursValue && this.holidayHoursValue,

        this.timeslots
      )
    } else {
      this.availability = null
      this.timeslots    = this.presentValuesOf(
        booking && booking.hasOpeningHoursValue && this.mergedValuesOf(...Object.values(booking.openingHoursValue)),

        this.hasHolidayHoursValue && this.holidayHoursValue,
        this.hasOpeningHoursValue && this.presentValuesOf(...Object.values(this.openingHoursValue)),

        this.timeslots
      )
    }
  }

  selectValue(event) {
    super.selectValue(event)
    this.changed(event)
  }

  changed (event) {
    this.widget.refresh()
  }

  isAvailable(timeslot) {
    return this.widget.booking.timeslotAvailable(timeslot, this.availability)
  }

  isSqueezed(timeslot) {
    return this.widget.booking.timeslotSqueezed(timeslot, this.availability)
  }

  listItemHTML(option) {
    let value = option.value
    let label = option.innerText

    if (this.attributeAsLabelValue.length) {
      label = option.getAttribute(this.attributeAsLabelValue)
    }

    let htmlClass = (option.selected ? 'selected' : '')
    let action    = `data-action="click->${this.identifier}#selectValue"`

    if (this.isAvailable(value)) {
      htmlClass = `${htmlClass} select-scroll-value-available`
      if (this.isSqueezed(value)) {
        htmlClass = `${htmlClass} select-scroll-value-squeezed`
      }
    } else if (this.widget.booking.rejectValue) {
      action    = ''
      htmlClass = `${htmlClass} select-scroll-value-disabled`
    } else {
      htmlClass = `${htmlClass} select-scroll-value-unavailable`
    }

    return `
      <li class="${this.classNameValue}-value ${htmlClass.replace('  ', ' ').trim()}"
          ${action}
          data-${this.identifier}-value-param="${value}" >
        <span>${label}</span>
      </li>
    `
  }

  mergedValuesOf (...collections) {
    return _.union(
      ...collections.filter(
        (collection) => collection && collection.length !== undefined && collection.length > 0
      )
    ).sort()
  }

  presentValuesOf (...collections) {
    return collections.find(
      (collection) => collection && collection.length !== undefined && collection.length > 0
    )
  }
}
