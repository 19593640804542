import datePickerController from 'vendor/datepicker.js.erb'

export default class {
  get defaultOptions() {
    return {
      todayLabel:        'Vandaag',
      currentWeekFormat: 'l',
      currentYearFormat: 'l-cc-sp-F-sp-j',
      otherYearFormat:   'F-sp-j-cc-sp-Y'
    }
  }

  get translations() {
    return window.fdLocale
  }

  get isToday() {
    return this.dateIsSameDay(this.date, this.now)
  }

  get isThisWeek() {
    return this.dateWithinOneWeek(this.date)
  }

  constructor(date, options = {}) {
    this.date = new Date(date)
    this.now  = new Date()

    this.options = {
      ...this.defaultOptions,
      ...options
    }
  }

  dateIsSameDay(x, y) {
    return x.toDateString() == y.toDateString()
  }

  dateWithinOneWeek(date) {
    let subject   = this.date.getTime()
    let yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).getTime()
    let nextWeek  = new Date(new Date().setDate(new Date().getDate() + 6)).getTime()

    return subject > yesterday && subject <= nextWeek
  }

  humanizedDate() {
    if (this.isToday && this.options.todayLabel) {
      return this.options.todayLabel
    } else if (this.isThisWeek) {
      return this.formattedDate(this.options.currentWeekFormat)
    } else if (this.date.getYear() == this.now.getYear()) {
      return this.formattedDate(this.options.currentYearFormat)
    } else {
      return this.formattedDate(this.options.otherYearFormat)
    }
  }

  formattedDate(format) {
    let parts  = format.split("-")
    let result = []

    let d = this.date.getDate()
    let D = this.date.getDay()
    let m = this.date.getMonth()
    let y = this.date.getFullYear()

    let flags = {
      "sp": " ",
      "dt": ".",
      "sl": "/",
      "ds": "-",
      "cc": ",",
      "d":  this.pad(d),
      "D":  this.translations && this.translations.dayAbbrs[D == 0 ? 6 : D - 1],
      "l":  this.translations && this.translations.fullDays[D == 0 ? 6 : D - 1],
      "j":  d,
      "N":  D == 0 ? 7 : D,
      "w":  D,
      "W":  this.getWeekNumber(y,m,d),
      "M":  this.translations && this.translations.monthAbbrs[m],
      "F":  this.translations && this.translations.fullMonths[m],
      "m":  this.pad(m + 1),
      "n":  m + 1,
      "t":  this.daysInMonth(m, y),
      "y":  String(y).substr(2,2),
      "Y":  y,
      "S":  ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
    }

    for(let index = 0, part = null; part = parts[index]; index++) {
      result.push(!(part in flags) ? '' : flags[part])
    }

    return result.join('')
  }

  pad(value, length) {
    length = length || 2;
    return "0000".substr(0, length - Math.min(String(value).length, length)) + value
  }

  getWeekNumber(y,m,d) {
    let date = new Date(y, m, d, 0, 0, 0)
    let dow  = date.getDay()

    date.setDate(date.getDate() - (dow + 6) % 7 + 3); // Nearest Thu
    let ms = date.valueOf() // GMT

    date.setMonth(0)
    date.setDate(4) // Thu in Week 1

    return Math.round((ms - date.valueOf()) / (7 * 864e5)) + 1
  }

  daysInMonth(nMonth, nYear) {
    nMonth = (nMonth + 12) % 12
    return (((0 == (nYear%4)) && ((0 != (nYear%100)) || (0 == (nYear%400)))) && nMonth == 1) ? 29: [31,28,31,30,31,30,31,31,30,31,30,31][nMonth];
  }
}
